<template>
  <div id="user-list">

    <!-- state total card -->

    <!-- list filters -->
    <v-card>
      <v-card-title>
        {{ $t("Positions de dossiers") }}

      </v-card-title>

      <!-- table -->

      <v-card-text class="pt-2">
        <state-form
          v-model="item"
          :is-form-active="isFormActive"
          @refetch-data="fetchItems"
        >
          <template v-slot:form-title>
          </template>
          <template v-slot:form-cancel>
            <v-btn
              :loading="loading"
              color="secondary"
              outlined
              @click="isFormActive = !isFormActive"
            >
              {{ $t('Annuler') }}
            </v-btn>
          </template>
        </state-form>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="deleteDialog"
      touchless
      :right="!$vuetify.rtl"
      :width="$vuetify.breakpoint.smAndUp ? 300 : '100%'"
    >
      <v-card>
        <v-card-title>{{ $t("Confirmer la modification") }}</v-card-title>
        <v-divider />
        <v-card-text>{{ $t("Confirmez-vous de vouloir modifier ?") }}</v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn
            small
            outlined
            @click="deleteDialog = false"
          >
            {{ $t("Non") }}
          </v-btn>
          <v-spacer />
          <v-btn
            :loading="loading"
            small
            color="primary"
            @click="deleteItem"
          >
            {{ $t("Oui") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="isSnackbarBottomVisible"
      :color="$vuetify.theme.currentTheme.error"
      :light="$vuetify.theme.dark"
    >
      {{ snackBarMessage }}
    </v-snackbar>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiSquareEditOutline,
  mdiDotsVertical,
  mdiPlus,
  mdiClose,
  mdiFileDocumentOutline,
  mdiDeleteOutline,
  mdiExportVariant,
  mdiAccountOutline,
  mdiPencilOutline,
} from '@mdi/js'
import { onUnmounted, ref } from '@vue/composition-api'

// sidebar
import { avatarText, formatDate } from '@core/utils/filter'
import axios from '@axios'
import store from '@/store'
import StateForm from './StateForm.vue'
import userStoreModule from './itemStoreModule'

import controller from './Controller'
import RowsPerPage from '@/components/RowsPerPage'
import itemStoreModule from '@/views/apps/publications/itemStoreModule'
import HeaderFilters from '@/components/HeaderFilters'

export default {
  components: {
    HeaderFilters,
    RowsPerPage,
    StateForm,
  },
  setup() {
    const APP_STORE_MODULE_NAME = 'state'
    const superiors = ref([])
    const assistants = ref([])

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })

    const USER_APP_STORE_PUBLICATION_MODULE_NAME = 'app-publication'

    // Register module
    if (!store.hasModule(USER_APP_STORE_PUBLICATION_MODULE_NAME)) store.registerModule(USER_APP_STORE_PUBLICATION_MODULE_NAME, itemStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_PUBLICATION_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_PUBLICATION_MODULE_NAME)
    })

    const {
      item,
      items,
      tableColumns,
      searchQuery,
      totalItems,
      loading,
      options,
      selectedRows,
      isFormActive,
      snackBarMessage,
      isSnackbarBottomVisible,
      openDialog,
      deleteItem,
      confirmDelete,
      deleteDialog,
      fetchItems,
      filters,
    } = controller()

    axios
      .get('/listWorkers', {
        params: {
          term: '',
          type: 'states',
          rowsPerPage: 100,
          user: JSON.parse(localStorage.getItem('userData')).id || null,
        },
      })
      .then(response => {
        for (let i = 0; i < response.data.length; i++) {
          const u = response.data[i]
          const indexU = assistants.value.findIndex(el => el.id === u.id)
          if (indexU < 0) {
            assistants.value.push(u)
          }
        }
        const indexH = filters.value.findIndex(ele => ele.value === 'assistant.code')
        if (indexH > 0) {
          filters.value[indexH].filter.values = assistants.value/*.filter(el => el.role_name === 'assistant_indirect')*/
        }
        const indexC = filters.value.findIndex(ele => ele.value === 'assistant_cover.code')
        if (indexC > 0) {
          filters.value[indexC].filter.values = assistants.value.filter(el => el.role_name === 'assistant_indirect')
        }
        const indexGest = filters.value.findIndex(ele => ele.value === 'gestion.code')
        if (indexGest > 0) {
          filters.value[indexGest].filter.values = [
            { header: 'Gestionnaire', disabled: true },
            ...assistants.value.filter(el => el.role_name === 'gestionnaire'),
            { header: 'Assistants Direct', disabled: true },
            ...assistants.value.filter(el => el.role_name === 'assistant_direct')]
        }
        const indexPub = filters.value.findIndex(ele => ele.value === 'assistant_pub.code')
        if (indexPub > 0) {
          filters.value[indexPub].filter.values = assistants.value.filter(el => el.role_name === 'assistant_publication' || el.role_name === 'service_publications')
        }
      })

    return {
      item,
      items,
      tableColumns,
      searchQuery,
      totalItems,
      loading,
      options,
      isFormActive,
      selectedRows,
      snackBarMessage,
      isSnackbarBottomVisible,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      deleteDialog,
      confirmDelete,
      openDialog,
      deleteItem,
      avatarText,
      fetchItems,
      formatDate,
      filters,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPlus,
        mdiClose,
        mdiExportVariant,
        mdiAccountOutline,
        mdiPencilOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
